
.stonequest img.inline-preview {
    margin: 2rem auto;
    display: block;
    max-width: 75%;
    border: 1px solid black;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    image-rendering: pixelated;
}

.stonequest .entry-heading {
    padding-top: 2rem;
    padding-bottom: 3rem;
    text-align: center;
}

.stonequest .entry-heading > h5.heading {
    text-transform: uppercase;
    font-weight: bold;

    border-top: 2px solid rgba(255, 255, 255, 0.4);
    line-height: 0px;
}

.stonequest .entry-heading > h5.heading > span {
    background: #212121;
    padding: 0 0.5rem;
}

.stonequest .rest-onward {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #eee;
    color: #eee;
    text-align: center;
}

.stonequest .rest-onward::after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: #212121;
}
