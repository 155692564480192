body {
    color: rgba(232, 232, 232, 0.93);
    background-color: #222222;
    font-family: 'Overpass', sans-serif;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

h3 {
    font-size: 24px;
}

a.fancy-link {
    color: #18bc9c;
}

a:hover {
    color: #00bc8c;
}

hr {
    border-color: rgba(232, 232, 232, 0.93);
}

.section-header {
    padding-bottom: 0.25rem;
    margin: 40px 0 20px;
    border-bottom: 1px solid #555;
}

.btn {
    text-transform: uppercase;
    border-radius: 4px;
}

.btn.btn-primary {
    background-color: #10c195;
    border: 1px solid #10c195;
    color: #212121;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.btn:hover, .btn:not(:disabled):not(.disabled):active, .btn:focus {
    outline: 0;
}

.btn.btn-primary:hover, .btn.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #0f9e7b;
    border: 1px solid #0f9e7b;
    color: #212121;
}

.btn-group {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.btn-group > .btn.btn-primary {
    box-shadow: none;
}

.btn-group > .btn.btn-primary:first-child,
.btn-group > .btn.btn-primary:first-child:hover {
    border-right: none;
    z-index: inherit;
}

.btn-group > .btn.btn-primary:not(:first-child) {
    border-left: 1px solid #118b6d;
}

.btn.btn-secondary {
    background-color: transparent;
    border: 0;
}

.btn.btn-secondary:hover {
    background-color: #ffffff80;
}

.hidden {
    display: none !important;
}
.full-width {
    width: 100% !important;
}

.list-group.list-group-flush {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.list-group.list-group-flush:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.list-group-item-dark {
    background-color: #222;
    color: rgba(255, 255, 255, 0.93);
}

.list-group-item-action.list-group-item-dark:hover {
    background-color: #333;
    color: rgba(255, 255, 255, 0.93);
}

.text-small {
    font-size: 0.75rem;
}

input:disabled,
textarea:disabled {
    background-color: gray !important;
    border-color: gray;
}

.list-group .list-group-item-action.list-group-item-dark {
    color: #18bc9c;
    outline: 0;
}

.list-group .list-group-item-action.list-group-item-dark:focus {
    background-color: #333;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
