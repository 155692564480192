.not-found .sky {
    width: 10rem;
    height: 10rem;
    border: 1px solid black;
    margin: auto;
    position: relative;
    overflow: hidden;
    background-color: #7fdbff;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.not-found .cloud-anim {
    -webkit-animation: animateCloud 10s linear infinite;
    -moz-animation: animateCloud 10s linear infinite;
    animation: animateCloud 10s linear infinite;
}

.not-found .cloud-2-anim {
    -webkit-animation: animateCloud 10s linear infinite;
    -moz-animation: animateCloud 10s linear infinite;
    animation: animateCloud 10s linear infinite;

    -webkit-animation-delay: -6s;
    -moz-animation-delay: -6s;
    animation-delay: -6s;
}

.not-found .cloud{
    width: 173px;
    height: 57px;
    background: white;
    position: absolute;
    box-shadow: 1px 2px 4px 0px #ababab;

    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    transform: scale(0.45);
}

.not-found .cloud:before{
    content: "";
    display: block;
    width: 69px;
    height: 23px;
    background: white;
    top: -20px;
    position: absolute;
    left: 74px;
    box-shadow: 2px -2px 5px 0px rgba(0, 0, 0, 0.38);
    z-index: 0;
}

.not-found .cloud.cloud-2 {
    top: 45px;
}

.not-found .cloud.cloud-2:before {
    left: 20px;
}

.not-found .grassy-gnoll {
    background-color: #0c6919;
    width: 110%;
    height: 3rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
}

.not-found .cloud-shadow {
    background-color: #094c1265;
    width: 173px;
    height: 15px;
    position: absolute;
    left: 0;
    bottom: 1rem;

    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    transform: scale(0.45);
}

.not-found .cloud-2-shadow {
    background-color: #094c12;
    bottom: 0.5rem;
}

.not-found .grass-blade {
    background-color: #0c6919;

    position: absolute;
    bottom: 1.9rem;
    height: 5px;
    width: 2px;

    -webkit-animation: animateGrassBlade 5s ease-in infinite;
    -moz-animation: animateGrassBlade 5s ease-in infinite;
    animation: animateGrassBlade 5s ease-in infinite;
}

.not-found .sun {
    background-color: yellow;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    left: 2rem;
    top: 1rem;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.38);
}

.not-found .sun-ray {
    height: 36px;
    width: 2px;
    background-color: yellow;
    position: absolute;
    left: 9px;
    top: -8px;

    -webkit-animation: animateSunRays 30s linear infinite;
    -moz-animation: animateSunRays 30s linear infinite;
    animation: animateSunRays 30s linear infinite;
}

.not-found .sky.nighttime {
    background-color: #070B34;
}

.not-found .nighttime .cloud,
.not-found .nighttime .cloud:before {
    background-color: #bbb;
}

.not-found .nighttime .grassy-gnoll,
.not-found .nighttime .grass-blade {
    background-color: #085f4b;
}

.not-found .nighttime .sun {
    background-color: #ffffcc;
    box-shadow: 0 0 6px #ffffcc;
}

.not-found .nighttime .sun-ray {
    display: none;
}

.not-found .nighttime .cloud-shadow {
    background-color: #085946;
}

.not-found .nighttime .star {
    background-color: white;
    box-shadow: 0 0 3px white;

    width: 2px;
    height: 2px;
    position: absolute;
}

/* Sun rays */
@keyframes animateSunRays {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes animateSunRays {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-moz-keyframes animateSunRays {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Grass blades */
@-webkit-keyframes animateGrassBlade {
    0% {
        transform: skewX(0deg);
    }
    50% {
        transform: skewX(-25deg);
    }
    100% {
        transform: skewX(0deg);
    }
}
@-moz-keyframes animateGrassBlade {
    0% {
        transform: skewX(0deg);
    }
    50% {
        transform: skewX(-25deg);
    }
    100% {
        transform: skewX(0deg);
    }
}
@keyframes animateGrassBlade {
    0% {
        transform: skewX(0deg);
    }
    50% {
        transform: skewX(-25deg);
    }
    100% {
        transform: skewX(0deg);
    }
}

/* Clouds */
@-webkit-keyframes animateCloud {
    0% {
        left: -80%;
    }
    100% {
        left: 100%;
    }
}
@-moz-keyframes animateCloud {
    0% {
        left: -80%;
    }
    100% {
        left: 100%;
    }
}
@keyframes animateCloud {
    0% {
        left: -80%;
    }
    100% {
        left: 100%;
    }
}

