.home .welcome-fold {
    background-color: #10c195;
    color: #212121;
    text-align: center;
    padding: 2rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.home .welcome-fold .title-block {
    margin: auto;
}

.home .welcome-fold span.fancy {
    font-size: 7rem;
    height: 100%;
}

.home .welcome-fold .title {
    font-weight: bold;
}

.home .thicc-line {
    padding: 0;
    border: 0;
    max-width: 350px;
    border-top: solid 2px;
}

.nav.nav-tabs {
    border: 0;
    border-bottom: 2px solid #10c195;
    color: rgba(0, 0, 0, 0.93);
}

.nav.nav-tabs .nav-item.nav-link {
    border-radius: 0;
    border: 0;
    color: rgba(232, 232, 232, 0.93);
}

.nav.nav-tabs .nav-item.nav-link.active {
    font-weight: bold;
    background: #10c195;
    color: rgba(0, 0, 0, 0.93);
}

.nav.nav-tabs .nav-item.nav-link:hover {
    color: #10c195;
}

.nav.nav-tabs .nav-item.nav-link.active:hover {
    cursor: default;
    color: rgba(0, 0, 0, 0.93);
}

.project-preview-img {
    max-width: 100%;
    border: 1px solid #000;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.timeline-item-date {
    background: none;
}

.timeline .body-container {
    color: rgba(232, 232, 232, 0.93);
    text-align: justify;
}

.timeline-item-dateinner {
    color: rgba(0, 0, 0, 0.93);
}

.modal .youtube-modal {
    width: 100%;
    height: 100%;
    min-height: 350px;
}

.list-group-item-dark.no-interact,
.list-group-item-dark.no-interact:hover {
    background-color: rgba(0, 0, 0, 0.0);
}
