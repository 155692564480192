.worlds-between {
    text-align: justify;
}

.worlds-between .banner {
    background-color: #808080;
    background: no-repeat center center;
    background-attachment: scroll;
    background-size: cover;
    position: relative;
    padding-top: 20rem;
}

.worlds-between div.overlay {
    background-color: rgba(0, 0, 0, 0.0);
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.worlds-between .title-container {
    text-align: center;
    color: white;

    position: relative;
    top: -5rem;

    background: #111;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.worlds-between .title-container .heading {
    font-size: 4rem;
    font-family: 'Quicksand', sans-serif;
}

.worlds-between .title-container .divider {
    border-bottom: 2px solid white;
    max-width: 75%;
}

.worlds-between .additional-credits {
    background-color: black;
    margin-top: 5rem;
}
